import React, { Component } from "react"

// Styles
import Styles from "./GridSites.module.styl"

// Components
import BoxSite from "~components/Boxes/BoxSite"

class GridSites extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.renderRowsWithData = this.renderRowsWithData.bind(this)
    }

    state = {
        families: this.props.data,
        isHovering: false,
    }

    onBoxEnter() {
        this.setState({
            isHovering: true,
        })
    }

    onBoxOut() {
        this.setState({
            isHovering: false,
        })
    }

    renderBoxesInRow(items) {
        const Boxes = items.map((item, index) => {
            return (
                <div
                    className={`${Styles.GridSites__row__item}`}
                    key={`row-item-${index}`}
                >
                    <BoxSite
                        data={item}
                        onBoxEnter={this.onBoxEnter.bind(this)}
                        onBoxOut={this.onBoxOut.bind(this)}
                    />
                </div>
            )
        })

        return Boxes
    }

    renderRowsWithData() {
        const Rows = Object.values(this.state.families).map((family, index) => {
            return (
                <div
                    className={`${Styles.GridSites__row} ${
                        this.state.isHovering
                            ? Styles.GridSites__row__hovering
                            : ""
                    }`}
                    id={family.slug}
                    key={`row-${index}`}
                >
                    <div className={`${Styles.GridSites__row__left}`}>
                        <p
                            className={`teasing-3 ${Styles.GridSites__row__left__label}`}
                        >
                            {family.name}
                        </p>
                    </div>

                    {this.renderBoxesInRow(family.items)}
                </div>
            )
        })

        return Rows
    }

    render() {
        let Rows = false

        if (this.state.families) {
            Rows = this.renderRowsWithData()
        }

        return (
            <div className={`${Styles.GridSites}`}>
                <div className={`${Styles.GridSites__inner}`}>
                    {this.state.families && Rows}
                </div>
            </div>
        )
    }
}

export default GridSites
