import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Styles
import Styles from "./PanelChapo.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import Icon from "~icons/Icon"
import LinkPrimary from "~components/Links/LinkPrimary"

class PanelChapo extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={`${Styles.PanelChapo}`}>
                <Row>
                    <Cell start="2" end="12">
                        <div className={Styles.PanelChapo__inner}>
                            <div className={Styles.PanelChapo__top}>
                                <h2 className="teasing-2">
                                    Voici les 12 modèles de sites que nous avons
                                    identifiés. Ils sont issus de la combinaison
                                    de nos quatre dimensions.
                                </h2>
                            </div>
                            <div className={Styles.PanelChapo__body}>
                                <p>
                                    Chaque type de site est constitué d’une
                                    dimension principale et d’une coloration
                                    secondaire. Ainsi le site Flagship est en
                                    majeur Référence et en mineur Business, le
                                    site Engagé est en majeur Vision et en
                                    mineur Service, etc. Cette combinaison
                                    permet d’affiner le diagnostic, les idées
                                    pour guider votre projet et les priorités
                                    dans sa mise en œuvre.
                                </p>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelChapo
