import React, { Component } from "react"

// Styles
import Styles from "./SVGSite.module.styl"

class SVGSite extends Component {
    constructor(props) {
        super(props)
    }

    drawPolygon() {
        let polygonString = ""

        Object.entries(this.props.values).forEach((entry, index) => {
            // First key is name
            const entryName = entry[0],
                entryValue = entry[1]

            const RATIO = 16 / 12

            let x, y

            switch (entryName) {
                case "business":
                    x = -entryValue
                    y = 0
                    break
                case "vision":
                    x = 0
                    y = -entryValue
                    break
                case "service":
                    x = entryValue
                    y = 0
                    break
                case "reference":
                    x = 0
                    y = entryValue
                    break
            }

            polygonString += `${(x * RATIO).toString()},${y.toString()} `
        })

        return (
            <g transform="translate(8, 6)">
                <polygon
                    points={polygonString}
                    stroke="black"
                    strokeWidth="1"
                    vectorEffect="non-scaling-stroke"
                    fill="white"
                />
            </g>
        )
    }

    render() {
        return (
            <div className={`${Styles.SVGSite}`}>
                <svg className={`${Styles.SVGSite__graph}`} viewBox="0 0 16 12">
                    {this.drawPolygon()}

                    <line
                        x1="8"
                        y1="0"
                        x2="8"
                        y2="12"
                        stroke="black"
                        strokeWidth="1"
                        vectorEffect="non-scaling-stroke"
                    />
                    <line
                        x1="0"
                        y1="6"
                        x2="16"
                        y2="6"
                        stroke="black"
                        strokeWidth="1"
                        vectorEffect="non-scaling-stroke"
                    />
                </svg>
            </div>
        )
    }
}

export default SVGSite
