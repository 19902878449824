import React, { Component } from "react"

// Styles
import Styles from "./PanelTitle.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"

class PanelTitle extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={Styles.PanelTitle}>
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelTitle__inner}>
                            <h1
                                className={`heading-huge ${Styles.PanelTitle__title}`}
                            >
                                <div className={Styles.PanelTitle__item}>
                                    Les 12 modèles
                                </div>
                                <div
                                    className={`${Styles.PanelTitle__item} ${Styles.PanelTitle__item__last}`}
                                >
                                    de site
                                </div>
                            </h1>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelTitle
