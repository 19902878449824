import React, { Component } from "react"

import { navigate } from "gatsby"

// Styles
import Styles from "./BoxSite.module.styl"
import GridStyles from "~components/Grids/GridSites.module.styl"

// Components
import LinkPrimary from "~components/Links/LinkPrimary"
import SVGSite from "~components/SVGs/SVGSite"

// Libs
import slugify from "slugify"

const slugifyConfig = {
    replacement: "-",
    remove: undefined,
    lower: true,
    strict: true,
}

class BoxSite extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        isHovering: false,
    }

    onBoxEnter() {
        this.setState({
            isHovering: true,
        })

        this.props.onBoxEnter()
    }

    onBoxOut() {
        this.setState({
            isHovering: false,
        })

        this.props.onBoxOut()
    }

    onClickBox() {
        navigate(`/sites/${slugify(this.props.data.name, slugifyConfig)}`)
    }

    render() {
        let hoverClassNames = `${GridStyles.BoxSite__hovering} ${Styles.BoxSite__hovering}`

        return (
            <div
                ref="box"
                className={`${Styles.BoxSite} ${
                    this.state.isHovering
                        ? hoverClassNames
                        : GridStyles.BoxSite__idle
                }`}
                onMouseEnter={this.onBoxEnter.bind(this)}
                onMouseLeave={this.onBoxOut.bind(this)}
                onClick={this.onClickBox.bind(this)}
            >
                <div className={`${Styles.BoxSite__inner}`}>
                    <div className={`${Styles.BoxSite__top}`}>
                        <h4 className={`teasing-3`}>
                            <span
                                className={`result-number result-number--small`}
                            >{`${this.props.data.id
                                .toString()
                                .padStart(2, "0")}_ `}</span>
                            {this.props.data.name}
                        </h4>
                    </div>

                    <div className={`${Styles.BoxSite__body}`}>
                        <div className={`${Styles.BoxSite__graph}`}>
                            <div
                                className={`${Styles.BoxSite__graph__wrapper}`}
                            >
                                <div
                                    className={`${Styles.BoxSite__graph__labels}`}
                                >
                                    <div
                                        className={`${Styles.BoxSite__graph__labels__top}`}
                                    >
                                        <p
                                            className={`graph-label graph-label--opacified graph-label--small`}
                                        >
                                            Point de vue
                                        </p>
                                        <p
                                            className={`graph-label graph-label--small ${Styles.BoxSite__graph__label__center}`}
                                        >
                                            Vision
                                        </p>
                                        <p
                                            className={`graph-label graph-label--opacified graph-label--small`}
                                        >
                                            Engagement
                                        </p>
                                    </div>
                                    <div
                                        className={`${Styles.BoxSite__graph__labels__center}`}
                                    >
                                        <p
                                            className={`graph-label graph-label--small ${Styles.BoxSite__graph__label__left}`}
                                        >
                                            Business
                                        </p>
                                        <p
                                            className={`graph-label graph-label--small ${Styles.BoxSite__graph__label__right}`}
                                        >
                                            Service
                                        </p>
                                    </div>
                                    <div
                                        className={`${Styles.BoxSite__graph__labels__bottom}`}
                                    >
                                        <p
                                            className={`graph-label graph-label--opacified graph-label--small`}
                                        >
                                            Offre
                                        </p>
                                        <p
                                            className={`graph-label graph-label--small ${Styles.BoxSite__graph__label__center}`}
                                        >
                                            Référence
                                        </p>
                                        <p
                                            className={`graph-label graph-label--opacified graph-label--small`}
                                        >
                                            Outil
                                        </p>
                                    </div>
                                </div>
                                <SVGSite
                                    values={this.props.data.chartsValues}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${Styles.BoxSite__bottom}`}>
                        <p>{this.props.data.chapo}</p>
                    </div>

                    <div className={`${Styles.BoxSite__link}`}>
                        <LinkPrimary
                            extraClassNames={`${Styles.BoxSite__link__item}`}
                            to={`/sites/${slugify(
                                this.props.data.name,
                                slugifyConfig
                            )}`}
                            title="En savoir plus"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxSite
