import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

// Libs
import slugify from "slugify"
import { scroller } from "react-scroll"

// Styles
import Styles from "./PanelSites.module.styl"

// Component
import { Row, Cell } from "~layout/Layout"
import TopBarSite from "~components/TopBars/TopBarSite"
import GridSites from "~components/Grids/GridSites"

class PanelSites extends Component {
    constructor(props) {
        super(props)

        this.families = false
    }

    sortData(data) {
        const families = {}

        for (let i = 0, j = data.length; i < j; i++) {
            const currentData = data[i].node

            const slug = slugify(currentData.families.primary, {
                replacement: "-", // replace spaces with replacement character, defaults to `-`
                remove: undefined, // remove characters that match regex, defaults to `undefined`
                lower: true, // convert to lower case, defaults to `false`
                strict: true, // strip special characters except replacement, defaults to `false`
            })

            if (typeof families[slug] === "undefined") {
                families[slug] = {}
                families[slug]["items"] = []
                families[slug]["name"] = currentData.families.primary
                families[slug]["slug"] = slug
            }

            families[slug]["items"].push(currentData)
        }

        this.families = families
    }

    renderContentWithData(data) {
        this.sortData(data)

        return (
            <div className={`${Styles.PanelSites__inner}`}>
                <Row>
                    <Cell start="0" end="12">
                        <div className={`${Styles.PanelSites__top}`}>
                            <TopBarSite
                                label="Familles de site"
                                data={this.families}
                            />
                        </div>
                        <div className={`${Styles.PanelSites__body}`}>
                            <GridSites data={this.families} />
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }

    componentDidMount() {
        if (this.props.scrollTo) {
            this.scrollTo(this.props.scrollTo)
        }
    }

    scrollTo(item) {
        setTimeout(() => {
            scroller.scrollTo(item, {
                smooth: true,
                offset: -135,
                duration: 500,
            })
        }, 400)
    }

    render() {
        return (
            <div className={`${Styles.PanelSites}`}>
                <StaticQuery
                    query={query}
                    render={(data) =>
                        this.renderContentWithData(data.allSitesJson.edges)
                    }
                />
            </div>
        )
    }
}

// Query
export const query = graphql`
    query SitesQuery {
        allSitesJson {
            edges {
                node {
                    id
                    chapo
                    chartsValues {
                        business
                        vision
                        service
                        reference
                    }
                    families {
                        primary
                        secondary
                    }
                    name
                }
            }
        }
    }
`

export default PanelSites
