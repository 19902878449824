import React, { Component } from "react"

// Layout
import { Row, Cell, View, Panel, Footer } from "~layout/Layout"

// Components
import PanelTitle from "~components/Panels/SitesIndex/PanelTitle"
import PanelChapo from "~components/Panels/SitesIndex/PanelChapo"
import PanelSites from "~components/Panels/SitesIndex/PanelSites"
import CanvasBackground from "~components/Canvases/CanvasBackground"
import Metas from "~misc/Metas"

class SitesIndexPage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <View name="sites" theme="sites">
                <Metas
                    title={"Les 12 modèles de sites"}
                    description={
                        "Voici les 12 modèles de sites que nous avons identifiés. Ils sont issus de la combinaison de nos quatre dimensions."
                    }
                    image={""}
                    shouldIndex={true}
                />
                <CanvasBackground />
                <PanelTitle />
                <PanelChapo />
                <PanelSites
                    scrollTo={
                        this.props.location.state
                            ? this.props.location.state.slug
                            : false
                    }
                />

                <div className={`view__bottom`}>
                    <Footer />
                </div>
            </View>
        )
    }
}

export default SitesIndexPage
